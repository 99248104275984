*{
  margin: 0;padding: 0;
  box-sizing: border-box;


}
/* nav{
  width: 100%;
  height: 53px;
  background-color: rgba(245, 245, 255, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.nav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  width: 80%;
  height: 70px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 1);
  background: linear-gradient(to right, rgba(231, 248, 244, 1), rgba(222, 243, 249, 1), rgba(217, 239, 251, 1), rgba(212, 235, 254, 1), rgba(218, 235, 254, 1), rgba(226, 237, 254, 1), rgba(240, 238, 254, 1), rgba(255, 255, 255, 1));
 padding-left: 25px;
 padding-right: 25px;
 font-size: 16px;
 font-weight: 600;
 font-family: 'Nunito Sans', sans-serif;

}
.logo{
  height: 49px;
  width: 49px;
  background-color: rgba(217, 217, 217, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
  border-radius: 50%;
}
nav ul li {
  list-style: none;
}
.logo-ul{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;

}
.logo-ul ul {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.demo ul{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}
.demo button{
  padding: 12px 32px 12px 32px;
  border-radius: 8px;
  background-color: rgba(89, 86, 233, 1);
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 600;

} */
.banner{
  background-image: url(./Assets/2150911400.jpg);

  /* height: 100vh; */
  background-size: cover;
  background-position: center;

  padding: 100px;
}

.IMS{
  background-color: white;
  /* width: auto; */
  padding: 20px;
  border-radius: 12px;
  width: 561px;
  padding: 20px;
  font-family: 'Mulish', sans-serif;
}
.IMS-head {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.IMS-head-container {

  overflow-x: auto !important;
}
.IMS-head-container ul{
  display: flex;
  flex-direction: row;
  gap: 50px;
  background: linear-gradient(to right, rgba(231, 248, 244, 1), rgba(222, 243, 249, 1), rgba(217, 239, 251, 1), rgba(212, 235, 254, 1), rgba(218, 235, 254, 1), rgba(226, 237, 254, 1), rgba(240, 238, 254, 1), rgba(255, 255, 255, 1));
padding-top: 15px;
padding-bottom: 15px;
padding: 20px;

padding-left: 10px;
padding-right: 10px;
border-radius: 12px;
}
.IMS-head-container ul li{
  list-style: none;
  font-size: 13px;
  font-weight: 600;
  margin: 0 15px;
  cursor: pointer;
  white-space: nowrap; 
}
.IMS p{
  color: rgba(89, 86, 233, 1);
  font-size: 17px;
  font-weight: 800;

}
.IMS h1{
  font-size: 51px;
  font-weight: 800;
}
.IMS h3{
  color: rgba(75, 85, 99, 1);
  font-size: 13px;
  font-weight: 600;
}


.box-btns{
  display: flex;
  flex-direction: row;
  gap: 20px
}
.box-btns button{
  padding: 12px 32px 12px 32px;
  border-radius: 8px;


}
.core{
  width: 30%;
}
.book{
  background-color: rgba(89, 86, 233, 1);
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.w2{
  border: 1px solid rgba(212, 212, 212, 1);
  background: none;
  font-size: 16px;
  font-weight: 600;
}
.stars{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
.rate{
  text-align: center;
}
.rate p{
  margin-top: 5px;
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  font-weight: 600;
}

.corporate{
  background-color: rgba(245, 245, 255, 1);
  text-align: center;
  padding-top: 100px;
  font-family: 'Mulish', sans-serif;
  padding-left: 50px;padding-right: 50px;

}
.corporate h3{
  color: rgba(89, 86, 233, 1);
  font-size: 24px;
  font-weight: 800;
}
.corporate h2{
  font-size: 32px;
  font-weight: 500;
}
.corporate h2 span{
  font-weight: 800;
}
.aboutvision{
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: 'Mulish', sans-serif;

}
.aboutvision-left , .aboutvision-right{
  width: 50%;
}
.aboutvision-right img{
  width: 90%;

}
.aboutvision-left{
  padding: 100px;
}
.aboutvision-left h3 , .aboutproducts p{
  font-size: 24px;
  font-weight: 800;
  color: rgba(89, 86, 233, 1);
}
.aboutvision-left h2{
  font-size: 24px;
  font-weight: 800;
  
}
.aboutvision-left p{
  font-size: 16px;
  font-weight: 600;
  color: rgba(75, 85, 99, 1);
}
.aboutvision-left button{
  padding: 12px 32px 12px 32px;
  background-color: rgba(89, 86, 233, 1);
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.aboutvision-right{
  padding: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  /* background-image: url(./Assets/Ellipse\ 6.svg); */
}
.aboutproducts{
  text-align: center;
  font-family: 'Mulish', sans-serif;

}
.aboutproducts h3{
font-size: 32px;
font-weight: 800;
}
.lap{
display: flex;
flex-direction: row;
justify-content: center;
gap: 30px;
}
.lap-one{
  Width:
  376px;
  Height:
  374px;
}
.lap-one-box{
  background-color: rgba(225, 242, 255, 1);
  border-radius: 20px;
  padding: 30px;

}
/* .lap-one-box img{
  height: 250px;
} */

.lap-two{
  background-color: rgba(226, 251, 244, 1);
  padding: 20px;
  border-radius: 20px;
}
.lap-one-box h3 , .lap-two h3{
  font-size: 24px;
  font-weight: 800;
 color: rgba(89, 86, 233, 1);
  font-family: 'Mulish', sans-serif;

}
.lap-two img{
  width: 60%;

}
.lap-one-box p , .lap-two p{
  font-size: 16px;
  font-weight: 600;
  font-family: 'Mulish', sans-serif;
  color: black;

}
.hiring{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 50px;
  font-family: 'Mulish', sans-serif;

}
.hiring-heading{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 100px;
  font-size: 16px;
  font-weight: 700;
}
.hiring h3{
  font-size: 24px;
  font-weight: 800;
  color: rgba(89, 86, 233, 1);
   font-family: 'Mulish', sans-serif;
text-align: center;
/* margin-bottom: 50px; */
}
.candidate-profile{
  background-color: rgba(89, 86, 233, 1);
  width: 80%;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
}
.candidate-profile-left , .candidate-profile-right{
  width: 50%;
  height: 100%;
 
}
.candidate-profile-right{
  /* height: 100px; */
  /* padding: 50px; */
  background-size: contain;
  background-repeat: no-repeat;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  

  /* background-image: url(../src/Assets/image\ 24.svg); */
}
.candidate-profile-right img{
  width: 70%;
}

.candidate-profile-left p {
font-size: 16px;
font-weight: 700;
color: white !important;

}
.candidate-profile-left h2{
  font-size: 26px;
  font-weight: 800;
  color: white !important;

  }
  .candidate-profile-left h3 {
    font-size: 16px;
    font-weight: 600;
    color: white !important;
    text-align: left;
    }
    .candidate-profile-left button{
      padding: 12px 32px 12px 32px;
      background: none;
      color: white;
      border: 1px solid white;
      border-radius: 8px;
    }
    .candidate-profile-left{
      padding-left: 100px;
      padding-top: 50px;
  font-family: 'Mulish', sans-serif;
  padding-bottom: 50px;

    }
    .practice{
      padding: 100px;
    }
    .practice p{
      font-size: 16px;
      font-weight: 700;
      font-family: 'Mulish', sans-serif;

    }
    .practice h2{
      font-size: 32px;
      font-weight: 800;
  font-family: 'Mulish', sans-serif;

    }
    .binary{
      width: 100%;
      display: flex;
      flex-direction: row;
      /* padding: 100px; */
  font-family: 'Mulish', sans-serif;

    }
    .binary-left , .binary-right{
      width: 50%;
      
    }
    .binary-left{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      padding-left: 100px;
    }

    .binary-right{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .binary-left h1{
      font-size: 32px;
      font-weight: 800;
    }
    .binary-left p{
      font-size: 16px;
      font-weight: 600;
    }
    .binary-left button{
      padding: 12px 32px 12px 32px;
      background-color: rgba(89, 86, 233, 1);
      border-radius: 8px;
      border: none;
      color: white;
      font-size: 16px;
      font-weight: 600;
    }
    .tec-Interview{
      background-color: rgba(245, 245, 255, 1);
      padding-top: 100px;
      padding-bottom: 100px;
      padding-left: 200px;
      padding-right: 200px;
  font-family: 'Mulish', sans-serif;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  gap: 30px;
  text-align: center;
    }
    .tec-Interview p{
      font-size: 24px;
      font-weight: 800;
      color: rgba(89, 86, 233, 1);
    }
    .tec-Interview h1{
      font-size: 32px;
      font-weight: 800;
    }
    .tec-Interview h3{
      font-size: 16px;
      font-weight: 600;
      color: rgba(75, 85, 99, 1);
    }
    .tec-Interview button{
      padding: 12px 32px 12px 32px;
      background-color: rgba(89, 86, 233, 1);
      border-radius: 8px;
      border: none;
      color: white;
      font-size: 16px;
      font-weight: 600;
    }
    .TESTMONIALS{
      text-align: center;
      padding-top: 50px;
      padding-bottom: 50px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
  font-family: 'Mulish', sans-serif;

    }
    .TESTMONIALS p{

color: rgba(89, 86, 233, 1);
font-size: 24px;
font-weight: 800;
    }
    .TESTMONIALS h1{
      font-size: 32px;
font-weight: 800;
    }
    .TESTMONIALS h2{
      font-size: 16px;
font-weight: 600;
color: rgba(75, 85, 99, 1);
    }
    
    .TESTMONIALS-section{
      height: 480px;
      display: flex;
      align-items: center;
      padding-left: 50px;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: linear-gradient(to right,
        rgba(89, 86, 233, 1),
        rgba(89, 86, 233, 1),
        rgba(76, 74, 199, 0.86),
        rgba(54, 52, 142, 0.61),
        rgba(0, 0, 0, 0)
    ), url(./Assets/image\ 29.svg);
    }

    .review{
      Width:
513px;
Height:
384px;
background-color: white;
border-radius: 16px;
padding: 20px;
font-family: 'Mulish', sans-serif;

    }
    .review-head{
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      
    }
    .review-head p{
      font-size: 20px;
      font-weight: 700;
    }
    .review-head h2{
      font-size: 17px;
      font-weight: 600;
    }
    .review h2{
      font-size: 16px;
      font-weight: 600;
    }
    .review button{
      padding: 12px 32px 12px 32px;
      background-color: rgba(89, 86, 233, 1);
      border-radius: 8px;
      border: none;
      color: white;
    }
    .scale{
      background-color: rgba(245, 245, 255, 1);

    }
    .scale h3{
      font-size: 24px;
      font-weight: 800;
      color: rgba(89, 86, 233, 1);
    }
    .scale h2{
      font-size: 32px;
      font-weight: 800;
    }
    .scale p{
      font-size: 16px;
      font-weight: 700;
    }
    .scale button{
      background-color: rgba(89, 86, 233, 1);
      border: none;
      color: white;
      border-radius: 8px;
      padding: 12px 32px 12px 32px;
    }
    .platform{
      display: flex;
      flex-direction: row;
      background-color: rgba(245, 245, 255, 1);
  font-family: 'Mulish', sans-serif;

    }
    .platform-left , .platform-right{
      width: 50%;
      padding: 50px;
     
    }
    .platform-left {
      padding-top: 100px;
    }
    .platform-left h2{
      color: rgba(89, 86, 233, 1);
      font-size: 24px;
      font-weight: 800;
    }
      .platform-left h1{

      font-size: 32px;
      font-weight: 800;
    }
    .platform-left p{

      font-size: 16px;
      font-weight: 800;
    }
   
    .platform-left button{
      padding: 12px 32px 12px 32px;
      background-color: rgba(89, 86, 233, 1);
      color: white;
      font-size: 16px;
      font-weight: 600;
border-radius: 8px;
border: none;
    }
    .pagetwo{
      /* height: 500px; */
      height: auto;
      background-image: linear-gradient(to right,
      rgb(0, 0, 0), rgba(0, 0, 0, 0.829)
  ), url(./Assets/image\ 36\ \(1\).svg);
  background-size: cover;

  display: flex;
  flex-direction: row;
  }
    
  .pagetwo-left , .pagetwo-right{
width: 50%;
padding-top: 50px;

  }
  .pagetwo-left{
    padding-top: 120px;
    color: white;
  font-family: 'Mulish', sans-serif;
  padding-left: 50px;

  }
.pagetwo-left h1{
  font-size: 50px;
  font-weight: 700;
}
.pagetwo-left p{
  font-size: 16px;
  font-weight: 600;
}
    
    
.pagetwo-right{
  display: flex;
  align-items: end;
  justify-content: end;
}
.marq{
  height: 68px;
  background: linear-gradient(to bottom, rgba(89, 86, 233, 1), rgba(255, 151, 227, 1));

}
.marq{
  font-size: 24px;
  font-weight: 800;
  color: rgba(255, 255, 255, 1);
  font-family: 'Mulish', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

}
.event-btns{
  height: 73px;
  display: flex;
  flex-direction: row;

}
.event-btns button{
height: 100%;
width: 25%;
color: white;
border: 1px solid rgba(255, 255, 255, 1);

}
.event-btns{
  background-color: rgba(14, 13, 80, 1);


}
.b1{
  background-color: rgba(89, 86, 233, 0.15);

  font-family: 'Mulish', sans-serif;
font-size: 30px;
font-weight: 800;
}
.b2{
  background-color: rgba(14, 13, 80, 1);

  font-family: 'Mulish', sans-serif;
  font-size: 30px;
  font-weight: 800;
}
.vision{
background-color: rgba(23, 22, 69, 1);
display: flex;
flex-direction: row;
}
.vision-left , .vision-right{
  width: 50%;
}
.vision-left{
  padding-left: 50px;
  padding-top: 100px;
}
.vision-right{
  padding: 100px;
  display: flex;

  align-items: center;
}
.visionbtn {
  width: 187px;
  height: 60px;
  background-color: rgba(23, 22, 69, 1);
  color: white; 
  text-align: center; 
  line-height: 60px;

  font-family: 'Mulish', sans-serif;
font-size: 24px;
font-weight: 800;
  border: 2px solid transparent;
  background-clip: padding-box;

  
  border-image: linear-gradient(to bottom right, rgba(255, 151, 227, 1), rgba(149, 67, 151, 1), rgba(67, 15, 148, 1), rgba(132, 42, 221, 1), rgba(89, 86, 233, 1));
  border-image-slice: 1;
  border-radius: 30px;
}
.visionbtn2{
  width: 300px;
  height: 60px;
  background-color: rgba(23, 22, 69, 1);
  color: white; 
  text-align: center; 
  line-height: 60px;

  font-family: 'Mulish', sans-serif;
font-size: 24px;
font-weight: 800;
  border: 2px solid transparent;
  background-clip: padding-box;

  
  border-image: linear-gradient(to bottom right, rgba(255, 151, 227, 1), rgba(149, 67, 151, 1), rgba(67, 15, 148, 1), rgba(132, 42, 221, 1), rgba(89, 86, 233, 1));
  border-image-slice: 1;
  border-radius: 30px;
}

.vision-left button , .hiriing-left button{
  background: linear-gradient(to right, rgba(89, 86, 233, 1), rgba(255, 151, 227, 1));
  height: 46px;
  width: 175px;
  border-radius: 8px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: white;

}
.vision-left h1{
  font-family: 'Mulish', sans-serif;
color: white;
  font-size: 41px;
  font-weight: 800;
}
.vision-right p{
  font-size: 16px;
  font-weight: 600;
  font-family: 'Mulish', sans-serif;
  color: white;
}
.largestAI{
background-color: rgba(23, 22, 69, 1);
height: auto;
display: flex;
flex-direction: column;
justify-content: center;
padding-top: 50px;
align-items: center;
}
.largestAI-section{
  width: 90%;
  height: 100%;
background-color: rgba(255, 255, 255, 0.041);
border: 1px solid rgba(255, 255, 255, 0.5);
border-radius: 20px;
color: white;
font-family: 'Mulish', sans-serif;

padding: 30px;
}
.slider{

    display: flex;

    flex-direction: row;
    overflow-x: scroll !important;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch ;
    gap: 20px;
}
.slider-card{
  Width:
306px;
Height:
494px;
background-image: linear-gradient(to bottom, rgba(89, 86, 233, 0), rgba(89, 86, 233, 1)), url('./Assets/image 64.svg');
background-size: cover; /* Adjust as needed */
display: flex;
justify-content: center;
align-items: center;
flex: 0 0 auto;
margin-right: 10px; /* Adjust the margin as needed */
scroll-snap-align: start;
}
.slider-card button{

Width:
212px;
Height:
53px;
border: 1px solid rgba(255, 255, 255, 1);
font-size: 14px;
font-weight: 800;
font-family: 'Mulish', sans-serif;
border-radius: 51px;
color: white;
background: none;
}
.slider::-webkit-scrollbar {
  display: none; /* Webkit (Safari, Chrome) */
}
.hiriing{
  width: 90%;
/* height: 100px; */
background-color: rgba(255, 255, 255, 0.041);
border: 1px solid rgba(255, 255, 255, 0.5);
border-radius: 20px;
color: white;
font-family: 'Mulish', sans-serif;

padding: 30px;
}
.hiriing{
display: flex;
flex-direction: row;
}
.hiriing-left , .hiriing-right{
  width: 50%;
  padding-top: 50px;
}
.hiriing-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hiriing-right img{
  height: 80%;
}
.hiriing-left h1 {
  font-size: 41px;
  font-weight: 800;
}

.hiriing-left h1 span{
  background: linear-gradient(to right, rgba(89, 86, 233, 1), rgba(255, 151, 227, 1));
  color: transparent; /* Set text color to transparent initially */
  -webkit-background-clip: text; /* Clip the background to the text */
  background-clip: text;


}
.CAD{
  font-size: 11px;
  font-weight: 400;
font-family: 'Mulish', sans-serif;
color: rgba(46, 68, 141, 1);
text-align: center;
/* transform: translate(-50%, -50%); */
transition: all 0.3s ease;
}
.workshop{
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.skill{
  font-size: 41px;
  font-weight: 800;
font-family: 'Mulish', sans-serif;

color: white;
}
.festival{
  display: flex;
flex-direction: row;
background-color: rgba(23, 22, 69, 1);
font-family: 'Mulish', sans-serif;


}
.festival-left , .festival-right{
  width: 50%;
color: white;
padding: 30px;

}
.festival-left{
  padding-left: 150px;
}
.generalAdmission h2{
  font-size: 24px;
  font-weight: 800;

}
.generalAdmission p{
  font-size: 18px;
  font-weight: 500;

}
.festival-left button{
  padding: 12px 32px 12px 32px;
  border-radius: 8px;
  background: linear-gradient(to right, rgba(89, 86, 233, 1), rgba(255, 151, 227, 1));
border: none;
color: white;
font-size: 16px;
font-weight: 600;
font-family: 'Nunito Sans', sans-serif;
}
.ENDQUOTE{
  background-image: url(./Assets/Frame\ 77.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  color: white;
}
.endq{
  Width:
176px;
Height:
60px;
  background-color: rgba(23, 22, 69, 1);
  color: white; 
  text-align: center; 
  line-height: 60px;

  font-family: 'Mulish', sans-serif;
font-size: 24px;
font-weight: 800;
  border: 2px solid transparent;
  background-clip: padding-box;

  
  border-image: linear-gradient(to bottom right, rgba(255, 151, 227, 1), rgba(149, 67, 151, 1), rgba(67, 15, 148, 1), rgba(132, 42, 221, 1), rgba(89, 86, 233, 1));
  border-image-slice: 1;
  border-radius: 30px;
}
.ENDQUOTE h1{
  font-size: 72px;
  font-weight: 800;
  font-family: 'Mulish', sans-serif;

}
.ENDQUOTE p{
  font-size: 24px;
  font-weight: 700;
  font-family: 'Mulish', sans-serif;

}
.ENDQUOTE button{
 padding: 12px 32px 12px 32px;
  border: 1px solid white;
color: white;
font-size: 16px;
font-weight: 600;
font-family: 'Nunito Sans', sans-serif;
background: none;
border-radius: 8px;

}
.demoo{
  background-color: rgba(23, 22, 69, 1);
  padding: 50px;
  color: white;
  display: flex;
  flex-direction: row;

}
.demoo h1{
  font-size: 41px;
  font-weight: 700;
  font-family: 'Mulish', sans-serif;
}
.demoo p{
  font-size: 16px;
  font-weight: 600;
  font-family: 'Mulish', sans-serif;
}
.demo-text{
  padding: 100px;
}
.demo-text input{
  /* background-color: rgba(255, 255, 255, 1); */
  Width:
296px;
Height:
44px;
border-radius: 43px;
color: grey;
padding-left: 20px;

}
.demo-text button{
  Width:
116px;
Height:
44px;
border-radius: 40px;
background: linear-gradient(to right, rgba(89, 86, 233, 1), rgba(255, 151, 227, 1));
border: none;
color: white;
font-size: 16px;
font-weight: 600;
font-family: 'Nunito Sans', sans-serif;

margin-left: 10px;

}
.demo-text  h4{
  font-size: 16px;
  font-weight: 700;

  font-family: 'Mulish', sans-serif;

}
.partnerbtn{
  width: 250px;
  height: 60px;
  background-color: rgba(23, 22, 69, 1);
  color: white; 
  text-align: center; 
  line-height: 60px;

  font-family: 'Mulish', sans-serif;
font-size: 24px;
font-weight: 800;
  border: 2px solid transparent;
  background-clip: padding-box;

  
  border-image: linear-gradient(to bottom right, rgba(255, 151, 227, 1), rgba(149, 67, 151, 1), rgba(67, 15, 148, 1), rgba(132, 42, 221, 1), rgba(89, 86, 233, 1));
  border-image-slice: 1;
  border-radius: 30px;
}
.partners{
  background-color: rgba(23, 22, 69, 1);

  padding-top: 50px;
  padding-bottom: 50px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.partners h1{
  font-family: 'Mulish', sans-serif;
  font-size: 41px;
  font-weight: 800;
}
.brn{
  display: flex;
  flex-direction: row;
  gap: 200px;

}
.accordion {
  /* max-width: 300px; */
  background-color: rgba(23, 22, 69, 1);


}
.center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.center h1{
  font-size: 41px;
  font-weight: 800;
  color: white;
  font-family: 'Mulish', sans-serif;

}

.accordion-section {
  /* border: 1px solid #ddd; */
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.accordion-header {
  width: 80%;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  /* background-color: #f1f1f1; */
  border-bottom: 1px solid rgba(245, 245, 245, 0.3);
  border-top: 1px solid rgba(245, 245, 245, 0.3);

  color: white;
  font-size: 23px;
  font-weight: 400;
font-family: 'Nunito Sans', sans-serif;

}

.accordion-content {
  /* display: block;
  padding: 10px; */
  color: white;
}
footer{
  background-color: rgba(23, 22, 69, 1);
margin-top: -10px;

}
.footer-top{
  display: flex;
  flex-direction: row;
  padding-left: 100px;
  padding-right: 100px;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 50px;
}
.f1 , .f2 , .f3{
  width: 30%;
}
.footer-top h3{
  font-size: 16px;
  font-weight: 600;
  color: rgba(89, 86, 233, 1);
  font-family: 'Mulish', sans-serif;

}
.footer-top p{
  font-family: 'Mulish', sans-serif;

  font-size: 16px;
  font-weight: 600;
  color: white;
}
.footer-top h4{
  font-family: 'Mulish', sans-serif;

  font-size: 16px;
  font-weight: 700;
  color: white;
}
.footer-top input{
  Width:
296px;
Height:
44px;
border-radius: 43px;
border: none;
padding-left: 20px;
}
.footerinput{
  display: flex;
  gap: 10px;
  flex-direction: row;
}
.f1 button{
  Width:
116px;
Height:
44px;
background-color: rgba(89, 86, 233, 1);
color: white;
font-size: 12px;
font-weight: 700;
font-family: 'Mulish', sans-serif;
border-radius: 43px;
border: none;


}
.f2 ul{

  font-size: 16px;
  font-weight: 600;
  color: rgba(89, 86, 233, 1);
  font-family: 'Mulish', sans-serif;
}
.f2 ul li{
  font-size: 16px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  font-family: 'Mulish', sans-serif;
  list-style: none;
  margin-top: 15px;
  font-size: 24px;
  font-weight: 500;
}
.f3 h5 , .f3 h2{

  font-size: 24px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-family: 'Mulish', sans-serif;
}
.f3 button{
  padding: 12px 32px 12px 32px;
  background-color: rgba(89, 86, 233, 1);
  color: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;

}
.footer-bottom{
  border-top: 1px solid rgba(255, 255, 255, 0.24);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 20px;
}
.footer-bottom ul{
  display: flex;
  flex-direction: row;
  gap: 30px;

}
.footer-bottom ul li{
  list-style: none;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Mulish', sans-serif;
color: white;
}



.apps{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-top: 40px;
}
.accordion2{
  background-color: rgba(89, 86, 233, 1);
}
.faq{
  font-family: 'Mulish', sans-serif;

  color: white;
  font-size: 24px;
  font-weight: 800;

}
.s1{
  background-image: url(./Assets/Group\ 321.svg);
}
.s2{
  background-image: url(./Assets/Group\ 322.svg);
}




@media(max-width:1470px){
  .candidate-profile-right img{
    height: 250px;
  }
}
@media(max-width:1200px){
  .lap{
    flex-direction: column;
  }
  .lap-one{
    height: auto;
    width: auto;
  }
  
  .lap-one-box{
    width: 100%;
  }
  .candidate-profile-left{
    padding-left: 30px;
  }
  .candidate-profile-right img{
height: 200px;
  }
  .practice{
    padding: 30px;
  }
  .dash1{
    width: 80vw;
  }
  .workshop {
    display: flex;
    overflow-x: auto !important;
    width: 100%;
  }

  .CAD {
    flex: 0 0 auto;
    margin-right: 20px; /* Adjust the margin as needed */
  }
  .festival-left{
    padding-left: 50px;
  }
  .festival-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .festival-right img{
    width: 80%;
  }
  }
  @media(max-width:1100px){
    .platform-right{
      display: flex;
      justify-content: center;
      align-items: center;
    }
.platform-right img{
  height: 300px;
}
  }
@media(max-width:900px){
  .aboutvision{
flex-direction: column;
  }
  .aboutvision-left , .aboutvision-right{
    width: 100%;
  }
  .aboutvision-left{
    padding: 30px;
  }


  .candidate-profile-left{
    padding-right: 30px;
  }
  .candidate-profile-left , .candidate-profile-right{
    width: 100%;
  }
  .candidate-profile{
flex-direction: column;
  }
  .candidate-profile-right{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .binary{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* padding: 100px; */
font-family: 'Mulish', sans-serif;

  }
  .binary-left , .binary-right{
    width: 100%;
    
  }
  .binary-left{
    padding-left: 30px;
    padding-bottom: 100px;
  }
  .footer-top{
    padding-left: 30px;
    padding-right: 30px;
  }
  .demo-text{
    padding: 30px;
  }
}
@media(max-width:970px){
.pagetwo-right{
  display: none;
}
.pagetwo-left{
  width: 100%;
  padding-bottom: 100px;
}
.b1 ,.b2{
  font-size: 20px;
}
.hiriing{
  flex-direction: column;
  
}
.hiriing-left , .hiriing-right{
  width: 100%;
}
.brn{
  gap: 50px;
}
}
@media(max-width:768px){
  .banner{
    padding: 20px;

    padding-top: 150px;
    padding-bottom: 50px;
  }
  .platform{
    flex-direction: column;
  }
  .platform-left , .platform-right{
    width: 100%;
  }
  .footer-top{
    flex-direction: column;
    gap: 50px;
  }
  .f1 , .f2 , .f3{
    width: 100%;
  }
  .pagetwo-left{
padding-left: 30px;
padding-right: 30px;
  }
  .vision{
    flex-direction: column;
  }
  .vision-left , .vision-right{
    width: 100%;
  }
  .vision-right{
    padding: 50px;
  }
  .skill{
    text-align: center;
  }
  .demoo{
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }
  .demoo img{
    width: 80% !important;
  }
  
}
@media(max-width:600px){
  .IMS{
    width: 98%;
    padding: 20px;
  }
  .box-btns{
    flex-direction: column;
  }
  .IMS h1{
    font-size: 31px;
    font-weight: 800;
  }
  .IMS p{
    font-size: 14px;
  }
  .stars{
    flex-direction: column;
    align-items: start;
  }
  .rate{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
  .corporate{
    padding: 30px;
  }
  .aboutvision-right{
    background-size: contain;
  }

  .hiring-heading{
    gap: 30px;
  }
  .review{
    Width:
    auto;
Height:
auto;
  }
    .TESTMONIALS-section{
      height: auto;
      padding-top: 30px;
      padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .tec-Interview{
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .hiriing-right img{
    height: 250px;
  }
  .festival{
    flex-direction: column;

  }
  .festival-left , .festival-right{
    width: 100%;
  }
  .ENDQUOTE{
    padding-left: 30px;
    padding-right: 30px;
  }
  .ENDQUOTE h1{
    font-size: 50px;
  }
}

@media(max-width:550px){
  .candidate-profile{
    width: 95%;
  }
  .candidate-profile-right img{
    height: 150px;
  }
  .binary-right img{
    height: 200px;
  }
  .apps{
    gap: 50px;
    flex-wrap: wrap;
  }
  .event-btns{
    height: auto;
  }
  .event-btns{
    flex-direction: column;
  }
  .event-btns button{
    width: 100%;
  }
  .b1 , .b2{
height: 50px !important;

  }
  .partners{
    text-align: center;
  }
  .brn {
    gap: 25px;
  }
  .brn img{
   height: 50px;
  }
}

.demoo img{
  width: 40%;
}
.hum{
  height: 40px;
  display: none;
}
@media(max-width:1270px){
  .firstul , .demo{
    display: none;
  }
  .hum{
    display: block;
  }
}
::-webkit-scrollbar {
  height: 1px;
  width: 0px; /* Set the width of the scrollbar */
}
.CAD:hover {
  height: 150px;
  width: 150px;
  box-shadow: 0px 0px 200px 0px rgba(89, 86, 233, 1);
  background-color: rgba(89, 86, 233, 1);
  padding: 30px;
  border-radius: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.test-tab ul{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.test-tab li{
  list-style: none;
  font-size: 16px;
  font-weight: 700;
font-family: 'Mulish', sans-serif;

}
.bar{
  height: 6px;
  width: 486px;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 40px;

}
.test-tab{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* YourComponent.css */

.test-tab ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.test-tab li {
  list-style: none;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Mulish', sans-serif;
  cursor: pointer;
  transition: color 0.3s ease; 
}

.test-tab li.active {
  color: rgba(89, 86, 233, 1); 
}

.bar {
  height: 6px;
  width: 0; 
  background-color: rgba(217, 217, 217, 1);
  border-radius: 40px;
  transition: width 0.3s ease; 
}

.test-tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.intro{
  height: 500px;
  background-image: url(./Assets/Frame\ 77.svg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.intro h1{
  font-size: 41px ;
  color: white;
  font-weight: 800;
font-family: 'Mulish', sans-serif;
text-align: center;

}
.works{
  Height:
577px;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 50px;
/* background-image: url(./Assets/Group\ 11.svg);
background-repeat: no-repeat; */
}
.worksa{
  Height:
  577px;
  width: 80%;
  background-image: url(./Assets/Group\ 11.svg);
  background-repeat: no-repeat;
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 20px;
background-position: center;
  }
.worksa button{
  padding: 12px 32px 12px 32px;
  background: linear-gradient(to right, rgba(89, 86, 233, 1), rgba(255, 151, 227, 1));
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
font-weight: 600;
font-family: 'Nunito Sans', sans-serif;
}
.PRICING{
  Width:
143px;
Height:
60px;
background-color: black;
color: white;
border-radius: 30px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-size: 24px;
font-weight: 800;
font-family: 'Mulish', sans-serif;

}
.cost{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cost h1{
  font-size: 41px;
font-weight: 800;
font-family: 'Mulish', sans-serif;
}
.prising-cards{
  display: flex;
  flex-direction: row;
  gap: 30px;
  
}
.prising-card{
  border: 2px solid rgba(89, 86, 233, 1);
  padding: 15px;
  width: 370px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(89, 86, 233, 1);
}
.prising-card li{
  list-style: none;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
  font-size: 13px;
  font-weight: 700;
font-family: 'Mulish', sans-serif;
margin-top: 10px;

}
.prising-card h1{
  font-size: 50px;
  font-weight: 800;

font-family: 'Mulish', sans-serif;

}
.prising-card h2{
  font-size: 50px;
  font-weight: 500;

font-family: 'Mulish', sans-serif;

}
.line{
  height: 2px;
  background-color: black;
}
.REGISTER {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

}

.REGISTER button{
  padding: 12px 32px 12px 32px;
  border-radius: 8px;
  background: linear-gradient(to right, rgba(89, 86, 233, 1), rgba(255, 151, 227, 1));
  color: white;
  font-size: 16px;
  font-weight: 600;
font-family: 'Nunito Sans', sans-serif;
border: none;

}

.whatover{
  background-color: rgba(89, 86, 233, 1);
  display: flex;
  flex-direction: row;

}
.whatover-left , .whatover-right{
  padding: 50px;
  width: 50%;

}
.whatover-left{
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */

}
.whatover-right{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.whatover-left h2{
  font-size: 24px;
  font-weight: 800;
font-family: 'Mulish', sans-serif;
color: white;
}
.whatover-left h3{
  font-size: 32px;
  font-weight: 800;
font-family: 'Mulish', sans-serif;
color: white;

}
.whatover-left p{
  font-size: 20px;
  font-weight: 800;
font-family: 'Mulish', sans-serif;
color: rgba(255, 149, 224, 1);
}
.dec{
font-family: 'Mulish', sans-serif;
padding: 100px;
text-align: center;
  color: white;
  background-image: url(./Assets/656094c4c20f4396594c0404_Hero-header-p-2000\ 1.svg);
}
.dec h1{
  font-size: 51px;
  font-weight: 800;

}
.dec p{
  font-size: 24px;
  font-weight: 700;

}
.speaker-section{
  display: flex;
  flex-direction: row;
  text-align: center;
  color: white;
}
.speaker{
  background-color: rgba(23, 22, 69, 1);
  padding: 30px;
  padding-top: 50px;
 border-width: 0px 0.5px 0.5px 0px;

  border-style: solid;
  
  border-color: rgba(255, 255, 255, 1);
  
  
}
.speaker h1{
  font-size: 20px;
  font-weight: 800;
font-family: 'Mulish', sans-serif;

}
.speaker p{
  font-size: 14px;
  font-weight: 600;
font-family: 'Mulish', sans-serif;

}
.speaker button{
  padding: 12px 32px 12px 32px;
  border-radius: 8px;
  background: linear-gradient(to right, rgba(89, 86, 233, 1), rgba(255, 151, 227, 1));
  color: white;
  font-size: 16px;
  font-weight: 600;
font-family: 'Nunito Sans', sans-serif;
border: none;
}
.gallery img{
  width: 100%;

}
.blogs{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;

}
.blogs h1{
  font-size: 41px;
  font-weight: 800;
font-family: 'Mulish', sans-serif;

}
.blog-card{
width: 397.42px;
border-radius: 25px;

}
.Insight{
  Width:
180.73px;
Height:
48px;
background-color: rgba(89, 86, 233, 1);
color: white;
border-radius: 18px;
display: flex;
justify-content: center;
align-items: center;
}
.blog-cards{
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.blog-desc{
  background-color: rgba(23, 22, 69, 1);
  color: white;
  padding: 20px;
  margin-top: -10px;
border-radius: 0 0 25px 25px;

}
.blog-desc p{
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
font-family: 'Mulish', sans-serif;

}
.blog-desc h4{
  font-size:24px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
font-family: 'Mulish', sans-serif;

}
.blog-desc h4{
  font-size:20px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
font-family: 'Mulish', sans-serif;

}

.scrollable-container {
  width: 100%;
  overflow-x: auto !important;
  white-space: nowrap;
  
}

.prising-cards {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
   /* Make the cards display in a row */
}

.prising-card {
  /* Ensure each card has enough space */

  margin-right: 10px; /* Adjust the spacing between cards as needed */
}
.pc{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 
}
@media(max-width:1250px){
  .pc{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media(max-width:768px){
  .whatover{
    flex-direction: column;
  }
  .whatover-left , .whatover-right{
    width: 100%;

  }
  .speaker-section{
    flex-direction: column;
  }
  .worksa{
    padding-left: 20px;
    padding-right: 20px;
  }
}
.blog-cards-container{
  width: 100%;
  overflow-x: auto !important;
  white-space: nowrap;
}
.workwithus{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: url(./Assets/Group\ 11.svg);
}
.workwithus h1{
font-family: 'Mulish', sans-serif;

  font-size: 41px;
  font-weight: 800;
  color: white;
}
.berainer{
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;

}
.berainer h2{
  font-family: 'Mulish', sans-serif;

  font-size: 41px;
  font-weight: 600;
  
}
.berainer p{
  font-family: 'Mulish', sans-serif;

  font-size: 21px;
  font-weight: 400;
  
}
.inpt-section{
  display: flex;
  justify-content: center;
  align-items: center;
}
.inpt{
  width: 50%;
  display: flex;
  flex-direction: column;
  font-family: 'Mulish', sans-serif;

}
.inpt label{
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  display: none;
}
.inpt input{
  height: 50px;
  padding-left: 15px;

}
.inpt button{
  background-color: rgba(89, 86, 233, 1);
   border-radius: 8px;
   color: white;
   width: 100%;
   height: 50px;
   border: none;
   font-size: 16px;
   font-weight: 600;
font-family: 'Nunito Sans', sans-serif;


}
@media(max-width:550px){
  .berainer{
    padding-left: 30px;
    padding-right: 30px;
  }
  .inpt{
    width: 90%;
  }
}

.blog-carda{
  height: auto;
  width: 350px;

  padding-bottom: 30px;
  border-radius: 30px;
  box-shadow: 5px 13px 50px -37px rgba(0,0,0,0.75);
}
.blog-carda img{
  width: 100%;

}
.blogdesc{
  padding-top: 10px;
  padding-left: 20px;
}
.blogdesc h1{
  font-size: 30px;
  font-family: 'Mulish', sans-serif;
font-weight: 800;

}
.blogdesc p{
  font-size: 20px;
  font-family: 'Mulish', sans-serif;
font-weight: 600;
color: rgb(158, 157, 157);
margin-top: 15px;
}
.blogdesc h6{
margin-top: 15px;
display: flex;
flex-direction: row;
align-items: center;
height: 30px;
gap: 20px;
  font-size: 16px;
   font-weight: 600;
font-family: 'Nunito Sans', sans-serif;

}
.blogdesc button{
  margin-top: 15px;
display: flex;
flex-direction: row;
align-items: center;

gap: 20px;
  font-size: 16px;
   font-weight: 600;
font-family: 'Nunito Sans', sans-serif;
background: none;
border: none;
}
.blog-cardsa{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  /* gap: 50px; */
}
.BD{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blogdetail{
  width: 80%;
padding-bottom: 50px;
}
.blogdetail h1{
  font-size: 41px;
  font-weight: 800;
  font-family: 'Mulish', sans-serif;

margin-top: 20px;
}
.blogdetail h3{
  font-size: 30px;
  font-weight: 600;
  font-family: 'Mulish', sans-serif;

margin-top: 15px;
}
.blogdetail p{
  font-size: 25px;
  font-weight: 600;
  font-family: 'Mulish', sans-serif;

margin-top: 15px;
}
.blogdetail h4{
  font-size: 16px;
  font-weight: 400;
  font-family: 'Mulish', sans-serif;

margin-top: 15px;

}
.blogdetail button{
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Nunito Sans', sans-serif;
  margin-top: 15px;
color: rgb(41, 41, 204);
}
.blogdetail img{
width: 100%;
}
.blog-cardsa {
  display: flex;
  overflow-x: auto !important;

  width: 100%; /* Set a fixed width or use max-width based on your design */
  margin-bottom: 20px; /* Adjust as needed */
  padding-left: 20px;
 padding-right: 20px;
}

.blog-carda {
  flex: 0 0 auto;
 
  margin-right: 20px; /* Adjust as needed */
}
.tech , .tech2{
  display: flex;
  flex-direction: row;
}
.tech-left , .tech-right {
  width: 50%;
  background-color: #200D3F;
  color: white;

}
.tech-right img{
  width: 100%;
  height: 100%;
}
.tech-left{
  padding: 50px;

}
.tech-left h1 , .tech2-right h1{
  font-size: 41px;
  font-weight: 800;
  font-family: 'Mulish', sans-serif;

}
.tech-left p , .tech2-right p{
  font-size: 16px;
  font-weight: 400;
  font-family: 'Mulish', sans-serif;
}
.tech2-left , .tech2-right{
  width: 50%;
  background-color: #232F3F;
  color: white;

}
.tech2-left img{
  width: 100%;
}
.tech2-right{
  padding: 50px;
}
.tech3{
  display: flex;
  flex-direction: row;

}
.tech3-left , .tech3-right{
  width: 50%;
}
.tech3-right img{
  width: 50%;
}
.tech3-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
.tech3-left{
  padding: 50px;
}
.tech3-left h1{
  font-size: 41px;
  font-weight: 800;
  font-family: 'Mulish', sans-serif;
}
.tech3-left p{
  font-size: 16px;
  font-weight: 400;
  font-family: 'Mulish', sans-serif;
}
.whyus-section{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.whyus{
  width: 60%;
}
.whyus h1{
  text-align: center;
  font-size: 41px;
  font-weight: 800;
  font-family: 'Mulish', sans-serif;
}
.whyus ul li{
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 14px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Mulish', sans-serif;
}
.about{
  padding: 50px;
  padding-left: 150px;
  padding-right: 150px;
}
.about h3{
  font-size: 20px;
  font-weight: 400;
  font-family: 'Nunito Sans', sans-serif;
}

.abt-img{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.abt-img img{
  width: 80%;
}
@media(max-width:550px){


.about{
  padding-top: 50px;
  padding: 20px;
}
}
@media(max-width:1150px){
  .tech {
    display: flex;
    flex-direction: column;
  }
  .tech-left , .tech-right {
    width: 100%;
    background-color: #200D3F;
    color: white;
  
  }
  .tech2{
    display: flex;
    flex-direction: column-reverse;
  }
  .tech2-left , .tech2-right{
    width: 100%;
    background-color: #232F3F;
    color: white;
  
  }
  .tech3{
    display: flex;
    flex-direction: column;
  
  }
  .tech3-left , .tech3-right{
    width: 100%;
  }
  .whyus{
    width: 90%;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  margin-top: 20px;
}


.news{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}
.news-left , .news-right{
width: 50%;

}
.link{
  text-decoration: none;
  color: black;
}

.dropdown {
  position: relative;
  display: inline-block;
 
  margin-top: 13px;
}

/* Style the button inside the dropdown */
.dropdown button {

  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  background: none;
  font-family: 'Nunito Sans', sans-serif;

}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Style the dropdown links */
.dropdown-content a {
  /* padding: 12px 16px; */
  display: block;
  text-decoration: none;
  color: black;
}

/* Change color on hover */
.dropdown-content a:hover {
background-color: #6c6a6c;
}


    /* Show the dropdown menu on button hover */
    .dropdown:hover .dropdown-content {
      display: block;
    }
    .contactus{
      padding: 30px;
    }
    .contactus h1{
      font-size:41px;
      font-weight: 800;
      font-family: 'Mulish', sans-serif;
      margin-bottom: 30px;
    }
    .contactus button{
      height: 50px;
      width: 150px;
      border-radius: 8px;
      background-color: rgba(89, 86, 233, 1);
      color: white;
      border: none;
      font-size: 16px;
      font-weight: 600;
      margin-top: 20px;
    }
    .cont-ip{
      display: flex;
      flex-direction: column;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Mulish', sans-serif;
    }
    .cont-ip input{
      height: 45px;
      margin-top: 15px;
      margin-bottom: 15px;
      padding-left: 20px;

    }
    .cont-ip input{

    }

    .devops{
      z-index: -1;
      position: relative;
      width: 50%;
    }
    .devops-detail , .devops-detail-section{
      padding: 30px;
  
    }
    .devops-detail h1 , .devops-detail-section h1{
      font-family: 'Mulish', sans-serif;
  font-size: 32px;
  font-weight: 700;
color: rgba(89, 86, 233, 1);

    }
    .devops-detail p , .devops-detail-section p{
      font-family: 'Nunito Sans', sans-serif;

  font-size: 20px;
  font-weight: 500;
    }
    .devops-detail-section{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
    }
    .devops-detail-section-left ,  .devops-detail-section-right{
      width: 50%;
    }
    .devops-detail-section-right{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .devops-detail-section-right img{
      width: 80%;
  
    }
    .aws{
     position: relative;
      width: 100%;
      z-index: -1;
    }
    .devops-detail-section-left h3 , .devops-detail-section h3{
      font-family: 'Syne', sans-serif;
      font-size: 25px;
      font-weight: 700;
    }

    .devops-detail-section-left p , .devops-detail p , .devops-detail-section p{
      /* font-family: 'Syne', sans-serif; */
      font-size: 18px;
      font-weight: 400;
    }
    @media(max-width:1200px){
      .devops-detail-section{
        flex-direction: column-reverse;
        
      }
      .devops-detail-section-left ,  .devops-detail-section-right{
        width: 100%;
      }
      .devops-detail-section-right img{
        width: 70%;
        margin-bottom: 15px;
    
      }
    }
    @media(max-width:600px){
      .devops{
        width: 70%;
        margin-top: 20px;
      }
    }

    .lines{
    
     
     
    }
 
    nav{
      width: 100%;
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 50px;
      padding-right: 50px !important;
     background-color: white;
     /* padding-bottom: px; */
     color: black !important;
     z-index: 10;

    }
    .bnav{
      color: rgb(0, 0, 0);
    }
    nav ul{
      display: flex;
      flex-direction: row;
      gap: 50px;
    }
    nav ul li{
      /* color: rgba(55, 71, 79, 1); */
      font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 400;
    list-style: none;
    color: white !important;
    
    }
    nav {

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 50px;
      padding-right: 50px;
    height: 80px;
    background-color: #F5F5FF;
   
    }
    
    nav img {
      /* max-height: 40px; */
    }
    
    nav ul {
      list-style-type: none;
      display: flex;
      margin: 0;
      padding: 0;
    }
    
    nav ul li {
      margin-right: 20px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 400;
    font-family: 'Rubik', sans-serif;
  
    }
    
    .hamburger {
      font-size: 30px;
      cursor: pointer;
      display: none;
    }
    #hamburger{
      color: rgb(0, 0, 0) !important;
    }
    
    .hamburger.open {
      transform: rotate(90deg);
    }
    
    nav ul.open {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      background-color: #ffffff;
      text-align: center;
      padding-bottom: 20px;
    }
    nav ul{

    }
    
    nav ul.open li {
      margin: 1px 0;
    }
    
    @media only screen and (max-width: 950px) {
      nav ul {
        display: none;
        flex-direction: column;
        position: absolute;
        top:20px;
        left: 0;
        width: 100%;
        background-color: #fffdfd;
        text-align: center;
      }
    .dropdown-content{
      width: 100% !important;
  
    }
    .ul-containt-wo{
      width: 100% !important;
  
    }
      nav ul li {
        margin: 10px 0;
      }
    
      .hamburger {
        display: block;
      }
      .dropdown-content{
        margin-top: 15px !important;
      }
    }
    .dropdown {
      position: relative;
     
    }
    .dropdown-content {
      width: auto;
      display: none;
      position: absolute;
      background-color: #3b3b3b;
      /* box-shadow: 0 8px 16px 0 rgba(106, 215, 229, 1); */
      z-index: 2;
      /* padding: 10px; */
      gap: 20px;
      margin-top: 1px;
      list-style: none;
      color: white;
    }
    .ul-containt{
      display: flex;
      flex-direction: row;
      color: white;
    }
    
    
    .ul-containt-wo{
      width: 180px;
      color: white;
    
    }
   
    
    .dropdown-content li:hover{
     /* background-color: #F5F5FF; */
    
    
    }
    .caselink{
      /* background-color: #200D3F; */
      font-size: 16px !important;
      font-weight: 600 !important;
      font-family: 'Nunito Sans', sans-serif !important;
    }
    .dropdown-content li{
      list-style: none;
      /* margin-top: 10px; */
      width: 100%;
     
    }
    .dropdown-content li a{
      text-decoration: none;
      color: white;
    
    
    }
    .dropdown-content li a:hover{
      /* background-color: #60BC0F; */
    }
    .dropdown:hover .dropdown-content,
    .dropdown-content:hover {
      display: block;
    }
    .dropdown:hover .dropdown-content,
    .dropdown-content:hover,
    .dropdown:hover {
      display: block;
      color: black;
    }
    .dropdown-content li {
      /* margin-top: 10px; */
      /* height: 30px !important; */
      padding: 10px;
      color: white !important;
    }
    .nav-contact{
      
    }
    .nav-contact img{
      height: 25px !important;
      float: right;
      margin-left: 30px;
      }
      .name{
        font-size: 30px;
        margin-left: 30px;
        font-weight: 700;
font-family: 'Nunito Sans', sans-serif;
        color: black;
      }
      .navlog{
        text-decoration: none;

      }
      .ul-containt-wo2{
        width: 180px;
        color: white;
      
      }
      .bnav{
        font-size: 16px !important;
        font-weight: 600 !important;
        font-family: 'Nunito Sans', sans-serif !important;
      }
    .navdemobutton{
      height: 100px;
    }
    .navdemobtn , .submit-form{
      padding: 12px 32px 12px 32px;
      border-radius: 8px;
      background-color: rgba(89, 86, 233, 1);
      color: white;
      border: none;
      font-size: 16px;
      font-weight: 600;
    }
    .inq-from{
      width: 100%;
padding-left: 50px;
padding-right: 50px;
padding-bottom: 50px;
    }
    .inq-from-ips{
      display: flex;
      flex-direction: row;
      margin-bottom: 35px;
      gap: 30px;
      justify-content: center;
    }
    .inq-from input{
      width: 100%;
border: none;
border-bottom: 1px solid gray;
    }
    .tech-down .dropdown-selector {
      padding: 10px;
      font-size: 16px;
  }
  .dropdown-selector{
    border: none ;
    border-bottom: 1px solid rgb(115, 113, 113);
  }
  
  /* Customize the appearance as needed */
  .tech-down .dropdown-selector:focus {
      outline: none; /* Remove the default focus outline */
      border: 1px solid #3498db; /* Add a border when focused if desired */
  }
.inq-from h1{
  font-size: 41px;
  font-weight: 700;
  font-family: 'Mulish', sans-serif;
}

.inq-from p{
  font-size: 16px;
font-weight: 600;
font-family: 'Nunito Sans', sans-serif;
}
footer{
  background-color: rgba(23, 22, 69, 1);
margin-top: -10px;

}
.footer-top{
  display: flex;
  flex-direction: row;
  padding-left: 50px;
  padding-right: 50px;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 50px;
}
.f1 , .f2 , .f3{
  width: 30%;
}
.footer-top h3{
  font-size: 16px;
  font-weight: 600;
  color: rgba(89, 86, 233, 1);
  font-family: 'Mulish', sans-serif;

}
.footer-top p{
  font-family: 'Mulish', sans-serif;

  font-size: 16px;
  font-weight: 600;
  color: white;
}
.footer-top h4{
  font-family: 'Mulish', sans-serif;

  font-size: 16px;
  font-weight: 700;
  color: white;
}
.footer-top input{
  Width:
296px;
Height:
44px;
border-radius: 43px;
border: none;
padding-left: 20px;
}
.footerinput{
  display: flex;
  gap: 10px;
  flex-direction: row;
}
.f1 button{
  Width:
116px;
Height:
44px;
background-color: rgba(89, 86, 233, 1);
color: white;
font-size: 12px;
font-weight: 700;
font-family: 'Mulish', sans-serif;
border-radius: 43px;
border: none;


}
.f2 ul{

  font-size: 16px;
  font-weight: 600;
  color: rgba(89, 86, 233, 1);
  font-family: 'Mulish', sans-serif;
}
.f2 ul li{
  font-size: 17px !important;
  font-weight: 600;
  color: rgb(255, 255, 255);
  font-family: 'Mulish', sans-serif;
  list-style: none;
  margin-top: 15px;
  font-size: 24px;
  font-weight: 500;
}
.f3 h5 , .f3 h2{

  font-size: 20px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-family: 'Mulish', sans-serif;
}
.f3 button{
  padding: 12px 32px 12px 32px;
  background-color: rgba(89, 86, 233, 1);
  color: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;

}
.footer-bottom{
  border-top: 1px solid rgba(255, 255, 255, 0.24);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 20px;
}
.footer-bottom ul{
  display: flex;
  flex-direction: row;
  gap: 30px;

}
.footer-bottom ul li{
  list-style: none;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Mulish', sans-serif;
color: white;
}
@media(max-width:768px){
  .footer-top{
    flex-direction: column;
    gap: 50px;
  }
  .f1 , .f2 , .f3{
    width: 100%;
  }
  .footer-top{

    padding-left: 30px;
    padding-right: 30px;
  }
  .footer-top ul{
    padding-left: 0px;
  }
  .f2 ul{
    margin-left: 1px;
  }
  .platform-right img{
    height: 200px;
  }
}
.kslogo{
  height: 80px;
  margin-left: 50px;
}
.content {
  padding-top: 30px;
}

.heading {
  font-size: 2.5rem;
  font-weight: 900; }

.form-control {
  border: none;
  background: #f3f3f3; }
  .form-control:active, .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #000;
    background: #f3f3f3; }

.col-form-label {
  color: #000; }

.btn, .form-control, .custom-select {
  height: 50px; }

.custom-select:active, .custom-select:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000; }

.btn {
  border: none;
  border-radius: 4px !important; }
  .btn.btn-primary {
    background: #000;
    color: #fff;
    padding: 15px 20px; }
  .btn:hover {
    color: #fff; }
  .btn:active, .btn:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none; }

.box {
  padding: 40px;
  background: #fff;
  -webkit-box-shadow: -30px 30px 0px 0 rgba(0, 0, 0, 0.08);
  box-shadow: -30px 30px 0px 0 rgba(0, 0, 0, 0.08); }
  .box h3 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center; }

label.error {
  font-size: 12px;
  color: red; }

#message {
  resize: vertical; }

#form-message-warning, #form-message-success {
  display: none; }

#form-message-warning {
  color: #B90B0B; }

#form-message-success {
  color: #55A44E;
  font-size: 18px;
  font-weight: bold; }

.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-weight: bold;
  font-size: 12px;
  color: #000; }
.navli{
  font-size: 16px;
  font-weight: 600;
  font-family: 'Mulish', sans-serif;
}
input[type="radio"] {
position: absolute;
width: 1px; /* Setting this to 0 make it invisible for VoiceOver */
height: 1px; /* Setting this to 0 make it invisible for VoiceOver */
padding: 0;
margin: -1px;
border: 0;
clip: rect(0 0 0 0);
overflow: hidden;
}
label{
  display:block;
  width:32%;
  border: 4px solid white;
  position:absolute;
  bottom:5px;
  cursor: pointer;
  transition: border-color 0.3s linear;
}

label.second{
  left:34%;
}
label.third{
  left:68%;
}

blockquote{
  margin:0;
  padding:30px;
  width:500px;
  background-color: #DB532B;
  color:white;
  box-shadow: 0 5px 2px rgba(0,0,0,0.1);
  position:relative;
  transition: background-color 0.6s linear;
}

blockquote:after { 
  content: " "; 
  height: 0; 
  width: 0; 
  position: absolute; 
  top: 100%; 
  border: solid transparent; 
  border-top-color: #DA532B;
  border-left-color:#DA532B;
  border-width: 10px; 
  left: 10%; 
} 
#second:checked ~ .two blockquote {
  background-color:purple;
}
.two blockquote:after{
  border: solid transparent; 
  border-top-color: purple;
  border-left-color:purple;
  border-width: 10px;
}
#third:checked ~ .three blockquote{
  background-color:#54885F;
}
.three blockquote:after{
  border: solid transparent; 
  border-top-color: #54885F;
  border-left-color: #54885F;
  border-width: 10px;
}
.quotes{
  position:absolute;
  color:rgba(255,255,255,0.5);
  font-size:5em;
}
.leftq{
  top:-25px;
  left:5px;
}
.rightq{
  bottom:-10px;
  right:5px;
}



.comments{
  font-size: 16px;
  font-weight: 600;
  font-family: 'Mulish', sans-serif;
}
.banner-section{
  width: 60%;
  /* background-color: #0000007f; */
  padding: 50px;
  font-family: 'Mulish', sans-serif;

}
.banner-section p{
  color: rgba(89, 86, 233, 1);
  font-size: 40px;
  font-weight: 800;
}
.banner-section h3{
  color: #f3f3f3;
  font-size: 20px;
  font-weight: 500;
}
.banner-section button{
  padding: 10px 20px 10px 20px;
background-color: rgba(89, 86, 233, 1);
color: white;
border: none;
border-radius: 8px;

}

.testimonial-container{
overflow-x: hidden;
height: auto;
padding-top: 50px;
padding-bottom: 50px;
}


.testimonial-itemss{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.testimonial-itemss-img img{
  border-radius: 50%;

}
.testimonial-itemss-name{
  padding-left: 200px;
  padding-right: 200px;
}
.testimonial-itemss-name h2{
  color: rgba(89, 86, 233, 1);
  font-family: 'Mulish', sans-serif;
  font-size: 32px;
  font-weight: 800;
}
.testimonial-itemss-name p{
 
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-weight: 500;
}
@media(max-width:768px){
  .testimonial-itemss-name{
    padding-left: 30px;
    padding-right: 30px;
  }
  .banner-section{
    width: 100%;
  }
}
.socialicon{
  font-size: 20px;
  display: flex;
  gap: 15px;
}
.active{
  color: rgba(89, 86, 233, 1);

}

.info-form{
  background-image: url(./Assets/job-support.jpg);
  /* height: 100vh; */
 
}
.info-form-opac{
  height: 100%;
  width: 100%;
  background-color: #00000071;
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;
}
.icon-bg{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  background-color: rgba(89, 86, 233, 1);
}
.info1{
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.info-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.info-form-left{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.info-form-right{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.info-form-div{
  background-color: white;
  width: 70%;
border-radius: 20px;
  padding: 50px;
}
.info-form-opac{
  display: flex;
  flex-direction: row;
}
.sendmsg{
  width: 100%;
  height: 50px;
  background-color: rgba(89, 86, 233, 1);
  color: white;
  border: none;
  border-radius: 10px;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.ServicesProvides{
  display: flex;
  flex-direction: row;
  padding-left: 100px;
  padding-right: 100px;
  gap: 50px;
}
.ServicesProvide{
  width: 50%;
padding: 30px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 1px 6px 26px -13px rgba(0,0,0,0.75);
}
.ServicesProvide-icon{
  height: 100px;
  width: 100px;
  background-color: rgba(89, 86, 233, 1);
  border-radius: 50%;
  font-size: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ServicesProvide h3{
  color: rgba(89, 86, 233, 1);
  font-size: 24px;
    font-weight: 800;
}

.ServicesProvide p{
  font-size: 16px;
  font-weight: 600;
  font-family: 'Mulish', sans-serif;
  color: black;
}


h1,
h2,
h3,
h4,
h5,
h6 {}
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
img {
max-width: 100%;
height: auto;
}
section {
    padding: 60px 0;
   /* min-height: 100vh;*/
}

.sec-title{
position:relative;
z-index: 1;
margin-bottom:60px;
}

.sec-title .title{
position: relative;
display: block;
font-size: 18px;
line-height: 24px;
color: rgba(89, 86, 233, 1);
font-weight: 500;
margin-bottom: 15px;
}

.sec-title h2{
position: relative;
display: block;
font-size:40px;
line-height: 1.28em;
color: #222222;
font-weight: 600;
padding-bottom:18px;
}

.sec-title h2:before{
position:absolute;
content:'';
left:0px;
bottom:0px;
width:50px;
height:3px;
background-color:#d1d2d6;
}

.sec-title .text{
position: relative;
font-size: 16px;
line-height: 26px;
color: #848484;
font-weight: 400;
margin-top: 35px;
}

.sec-title.light h2{
color: #ffffff;
}

.sec-title.text-center h2:before{
left:50%;
margin-left: -25px;
}

.list-style-one{
position:relative;
}

.list-style-one li{
position:relative;
font-size:16px;
line-height:26px;
color: #222222;
font-weight:400;
padding-left:35px;
margin-bottom: 12px;
}

.list-style-one li:before {
content: "\f058";
position: absolute;
left: 0;
top: 0px;
display: block;
font-size: 18px;
padding: 0px;
color: #ff2222;
font-weight: 600;
-moz-font-smoothing: grayscale;
-webkit-font-smoothing: antialiased;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1.6;
font-family: "Font Awesome 5 Free";
}

.list-style-one li a:hover{
color: rgba(89, 86, 233, 1);
}

.btn-style-one{
position: relative;
display: inline-block;
font-size: 17px;
line-height: 30px;
color: #ffffff;
padding: 10px 30px;
font-weight: 600;
overflow: hidden;
letter-spacing: 0.02em;
background-color: rgba(89, 86, 233, 1);
}


.about-section{
position: relative;
padding: 120px 0 70px;
}

.about-section .sec-title{
margin-bottom: 45px;
}

.about-section .content-column{
position: relative;
margin-bottom: 50px;
}

.about-section .content-column .inner-column{
position: relative;
/* padding-left: 30px; */
}

.about-section .text{
margin-bottom: 20px;
font-size: 16px;
line-height: 26px;
color: #848484;
font-weight: 400;
}

.about-section .list-style-one{
margin-bottom: 45px;
}

.about-section .btn-box{
position: relative;
}

.about-section .btn-box a{
padding: 15px 50px;
}

.about-section .image-column{
position: relative;
}

.about-section .image-column .text-layer{
position: absolute;
right: -110px;
top: 50%;
font-size: 325px;
line-height: 1em;
color: #ffffff;
margin-top: -175px;
font-weight: 500;
}

.about-section .image-column .inner-column{
position: relative;
padding-left: 80px;
padding-bottom: 0px;
}
.about-section .image-column .inner-column .author-desc{
position: absolute;
bottom: 16px;
z-index: 1;
background: rgba(89, 86, 233, 1);
padding: 10px 15px;
left: 96px;
width: calc(100% - 152px);
border-radius: 50px;
}
.about-section .image-column .inner-column .author-desc h2{
font-size: 21px;
letter-spacing: 1px;
text-align: center;
color: #fff;
margin: 0;
}
.about-section .image-column .inner-column .author-desc span{
font-size: 16px;
letter-spacing: 6px;
text-align: center;
color: #fff;
display: block;
font-weight: 400;
}
.about-section .image-column .inner-column:before{
content: '';
position: absolute;
width: calc(50% + 80px);
height: calc(100% + 160px);
top: -80px;
left: -3px;
background: transparent;
z-index: 0;
border: 44px solid rgba(89, 86, 233, 1);
}

.about-section .image-column .image-1{
position: relative;
}
.about-section .image-column .image-2{
position: absolute;
left: 0;
bottom: 0;
}

.about-section .image-column .image-2 img,
.about-section .image-column .image-1 img{
box-shadow: 0 30px 50px rgba(8,13,62,.15);
border-radius: 46px;
}

.about-section .image-column .video-link{
position: absolute;
left: 70px;
top: 170px;
}

.about-section .image-column .video-link .link{
position: relative;
display: block;
font-size: 22px;
color: #191e34;
font-weight: 400;
text-align: center;
height: 100px;
width: 100px;
line-height: 100px;
background-color: #ffffff;
border-radius: 50%;
box-shadow: 0 30px 50px rgba(8,13,62,.15);
-webkit-transition: all 300ms ease;
-moz-transition: all 300ms ease;
-ms-transition: all 300ms ease;
-o-transition: all 300ms ease;
transition: all 300ms ease;
}

.about-section .image-column .video-link .link:hover{
background-color: #191e34;

}
@media(max-width:990px){
  .ServicesProvides{
    padding-left: 50px;
    padding-right: 50px;
  }
  .sec-title h2{
    /* margin-top: 50px; */
font-size:25px;

  }
  .info-form-opac{
    flex-direction: column;
  }
   .info-form-right{
    width: 100%;
  }
  .info-form-left{
    width: 100%;
  }
  .info-form-div{
    width: 90%;
  }
  .info1{
    padding-left: 50px;
    padding-right: 50px;
  }
  .info-form-opac{
    padding-top: 50px;
  }
}
@media(max-width:768px){
  .ServicesProvides{
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: column;
    
  }
  .ServicesProvide{
    width: 100%;
  }
  .aboutproducts h3{
    font-size: 23px;
    font-weight: 800;
    }
    .banner{
      padding: 1px;
    }
}